import { FC, ReactNode } from "react";
import cs from "classnames";
import dayjs from "dayjs";

const WidgetMessage: FC<{
  icon?: ReactNode;
  isUser?: boolean;
  children: ReactNode;
  sentAt?: string;
  font?: string;
}> = ({ icon, isUser, children, sentAt, font }) => {
  return (
    <div className="flex items-center gap-4">
      {icon ?? null}
      <div className="flex flex-col gap-2 w-full">
        <div
          className={cs(
            isUser
              ? "bg-light-grey-03 p-4 ml-auto rounded-8 text-dark-grey-02"
              : "mr-auto border border-light-grey rounded-8 text-dark-grey-02 p-4"
          )}
        >
          <p className="text-outfit-bodyMedium whitespace-pre-wrap" style={{ fontFamily: font }}>
            {children}
          </p>
        </div>
        <p
          className={cs(
            "text-[#C5C7D0] text-outfit-bodyTiny",
            isUser && "text-right"
          )}
          style={{ fontFamily: font }}
        >
          {sentAt && <>{dayjs(sentAt).format("HH:mm")}</>}
        </p>
      </div>
    </div>
  );
};

export default WidgetMessage;
