export type WidgetSettings = {
  chatbot_name: string;
  font: string;
  font_color: string;
  primary_brand_color: string;
  introduction_message: string;
  chat_bubble: string;
  photo_url: string;
};

export type WidgetMessageSend = {
  content: string;
};

export enum AUTHOR_ROLE {
  "VISITOR" = "VISITOR",
}

export type WidgetInitConversation = {
  chat_id: string;
  visitor_id: string;
};

export type WidgetMessage = {
  id: string;
  room_id: string;
  author: {
    id: string;
    name: string | null;
    role: AUTHOR_ROLE;
    is_leading: false;
  };
  content: string;
  sent_at: string;
};

export type WidgetMessageResponse = {
  ai_message: WidgetMessage;
};
