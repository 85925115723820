import {
  WidgetInitConversation,
  WidgetMessage,
  WidgetSettings,
} from "@/api/types";
import { useCallback, useEffect, useState } from "react";

// TODO replace using jotai's atoms with built-in local storage sync

const MESSAGES_LOCAL_STORAGE_KEY = "MESSAGES";
const CHAT_ID_LOCAL_STORAGE_KEY = "CHAT_ID";
const VISITOR_ID_LOCAL_STORAGE_KEY = "VISITOR_ID";
const SETTINGS_LOCAL_STORAGE_KEY = "SETTINGS";
const UPDATE_DATE_STORAGE_KEY = "UPDATED_AT";

const EXPIRE_THRESHOLD = 1000 * 15; // number of ms in 15 minutes

const useWidgetDataWithLocalStorage = ({
  initConversationData,
  initSettingsData,
  onExpire,
}: {
  initConversationData: WidgetInitConversation | undefined;
  initSettingsData: WidgetSettings | undefined;
  onExpire: (callback: () => void) => void;
}) => {
  const [messages, setMessages] = useState<WidgetMessage[]>([]);
  const [initData, setInitData] = useState<WidgetInitConversation | null>(null);
  const [initSettings, setInitSettings] = useState<WidgetSettings | null>(null);

  const addMessage = useCallback(
    (message: WidgetMessage) => {
      const updatedMessages = [
        ...JSON.parse(localStorage.getItem(MESSAGES_LOCAL_STORAGE_KEY) || "[]"),
        message,
      ];
      localStorage.setItem(
        MESSAGES_LOCAL_STORAGE_KEY,
        JSON.stringify(updatedMessages)
      );
      setMessages(updatedMessages);
    },
    [messages]
  );

  useEffect(() => {
    const updatedAt = parseInt(
      localStorage.getItem(UPDATE_DATE_STORAGE_KEY) || ""
    );

    let alterUpdateDate = false;

    if (updatedAt && new Date().getTime() - updatedAt >= EXPIRE_THRESHOLD) {
      onExpire(() => (alterUpdateDate = true));
    }

    if (initConversationData) {
      alterUpdateDate = true;
      localStorage.setItem(
        CHAT_ID_LOCAL_STORAGE_KEY,
        initConversationData.chat_id
      );
      localStorage.setItem(
        VISITOR_ID_LOCAL_STORAGE_KEY,
        initConversationData.visitor_id
      );
    }

    if (initSettingsData) {
      alterUpdateDate = true;
      localStorage.setItem(
        SETTINGS_LOCAL_STORAGE_KEY,
        JSON.stringify(initSettingsData)
      );
    }

    if (alterUpdateDate) {
      localStorage.setItem(
        UPDATE_DATE_STORAGE_KEY,
        new Date().getTime().toString()
      );
    }

    const localMessages: WidgetMessage[] = JSON.parse(
      localStorage.getItem(MESSAGES_LOCAL_STORAGE_KEY) || "[]"
    );
    const chatId = localStorage.getItem(CHAT_ID_LOCAL_STORAGE_KEY);
    const visitorId = localStorage.getItem(VISITOR_ID_LOCAL_STORAGE_KEY);
    const settings = JSON.parse(
      localStorage.getItem(SETTINGS_LOCAL_STORAGE_KEY) || "{}"
    );

    if (localMessages.length) {
      setMessages(localMessages);
    }
    setInitData({
      chat_id: chatId || "",
      visitor_id: visitorId || "",
    });
    setInitSettings(settings);
  }, [initConversationData, initSettingsData]);

  return {
    messages,
    visitorId: initData?.visitor_id,
    chatId: initData?.chat_id,
    settings: initSettings,
    addMessage,
  };
};

export default useWidgetDataWithLocalStorage;
