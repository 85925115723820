import {
  useWidgetInitConversationMutation,
  useWidgetSettingsQuery,
} from "@/api";
import { useCallback, useEffect, useRef } from "react";
import useSyncedMessages from "./useSyncedMessages";
import useWidgetDataWithLocalStorage from "./useWidgetDataWithLocalStorage";
import useWidgetContext from "@/libs/useWidgetContext";

const useWidget = () => {
  const { apiKey } = useWidgetContext();
  const { data, mutate: initConversationMutation } =
    useWidgetInitConversationMutation(apiKey);
  const { data: widgetSettings, refetch: fetchSettings } =
    useWidgetSettingsQuery(apiKey);
  const {
    visitorId,
    chatId,
    messages: initialMessages,
    settings,
    addMessage,
  } = useWidgetDataWithLocalStorage({
    initConversationData: data,
    initSettingsData: widgetSettings,
    onExpire: (callback) => {
      fetchSettings().then(callback);
    },
  });
  const { messages, showLoader, sendMessageIternal } = useSyncedMessages({
    visitorId,
    chatId: chatId || "",
    initialMessages,
    handleMessage: addMessage,
  });
  const firstMessagePostponed = useRef<string | null>(null);

  const sendMessage = useCallback(
    (content: string) => {
      if (!visitorId) {
        firstMessagePostponed.current = content;
        initConversationMutation();
      } else {
        sendMessageIternal(content);
      }
    },
    [sendMessageIternal, visitorId, initConversationMutation]
  );

  useEffect(() => {
    if (firstMessagePostponed.current && visitorId) {
      sendMessageIternal(firstMessagePostponed.current as string);
      firstMessagePostponed.current = null;
    }
  }, [visitorId]);

  useEffect(() => {
    if (settings !== null && !settings.chatbot_name) {
      fetchSettings();
    }
  }, [settings]);

  return { messages, visitorId, chatId, settings, showLoader, sendMessage };
};

export default useWidget;
