import WidgetHeader from "./WidgetHeader";
import WidgetInput from "./WidgetInput";
import useWidgetContext from "@/libs/useWidgetContext";
import { motion } from "framer-motion";
import WidgetMessagesContainer from "./WidgetMessagesContainer";
import { MouseEvent, useCallback } from "react";
import AiDefaultImage from "@/assets/default-ai-image.png";
import useWidget from "./useWidget";
import {
  DEFAULT_BOT_NAME,
  DEFAULT_BUBBLE_MESSAGE,
  DEFAULT_WIDGET_COLOR,
} from "@/constants/values";

const WidgetContainer = () => {
  const { isOpen, setIsOpen } = useWidgetContext();
  const { messages, visitorId, settings, showLoader, sendMessage } =
    useWidget();

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if ((e.target as Element).tagName === "svg") {
        return;
      }
      setIsOpen(true);
    },
    [setIsOpen]
  );

  return (
    <motion.div animate={isOpen ? "open" : "closed"}>
      <motion.div
        variants={{
          open: {
            opacity: 0,
          },
          closed: {
            opacity: 1,
            transition: {
              delay: 0.3,
            },
          },
        }}
      >
        <p className="text-outfit-titleSmall text-[#252B42] z-[99999] rounded-8 p-4 fixed right-20 bottom-40 bg-white border border-light-grey shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.4)]">
          {settings?.chat_bubble || DEFAULT_BUBBLE_MESSAGE}
        </p>
      </motion.div>
      <motion.div
        onClick={handleClick}
        className="transition-all duration-150 fixed bottom-20 right-20 z-[99999]"
        variants={{
          open: {
            width: "500px",
            height: "700px",
            transition: {
              delay: 0,
              height: {
                delay: 0,
              },
              width: {
                delay: 0,
              },
            },
          },
          closed: {
            width: "64px",
            height: "64px",
            borderRadius: "50%",
            cursor: "pointer",
            transition: {
              duration: 0.1,
              background: {
                delay: 0.3,
                duration: 0.1,
              },
              overflow: {
                delay: 0.3,
              },
            },
          },
        }}
      >
        <motion.div
          variants={{
            closed: {
              borderRadius: "50%",
              transition: {
                delay: 0.1,
              },
            },
            open: {
              borderRadius: "1rem",
            },
          }}
          className="relative w-full h-full overflow-hidden flex flex-col rounded-2xl justify-between shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.4)]"
        >
          <div>
            <WidgetHeader
              name={settings?.chatbot_name || DEFAULT_BOT_NAME}
              color={settings?.primary_brand_color || DEFAULT_WIDGET_COLOR}
              photo={AiDefaultImage}
            />
          </div>
          <WidgetMessagesContainer
            firstMessage={settings?.introduction_message}
            photo={AiDefaultImage}
            messages={messages}
            visitorId={visitorId || ""}
            showLoader={showLoader}
            font={settings?.font || ""}
          />
          <WidgetInput sendMessage={sendMessage} />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default WidgetContainer;
