import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchJson } from "./apiClient";
import {
  WidgetInitConversation,
  WidgetMessageSend,
  WidgetSettings,
} from "./types";

export const useWidgetSettingsQuery = (apiKey: string = "") => {
  return useQuery({
    queryKey: ["widget-setting"],
    queryFn: (): Promise<WidgetSettings> =>
      fetchJson(`/widget/settings`, apiKey, {
        method: "GET",
      }),
    // can be fetched only using refetch method
    enabled: false,
    refetchInterval: 10000,
  });
};

export const useWidgetInitConversationMutation = (apiKey: string = "") => {
  return useMutation({
    mutationKey: ["widget-init-conversation"],
    mutationFn: (): Promise<WidgetInitConversation> =>
      fetchJson(`/chats/`, apiKey, {
        method: "POST",
      }),
  });
};

export const useWidgetSendMessageMutation = (
  visitorId: string | undefined,
  apiKey: string = ""
) => {
  return useMutation({
    mutationKey: ["widget-send-message"],
    mutationFn: (body: WidgetMessageSend): Promise<void> =>
      fetchJson(`/chats/${visitorId}`, apiKey, {
        method: "POST",
        body: JSON.stringify(body),
      }),
  });
};
