import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useWidgetSendMessageMutation } from "@/api";
import { pusherClient } from "@/api/pusherClient";
import { AUTHOR_ROLE, WidgetMessage, WidgetMessageResponse } from "@/api/types";
import useWidgetContext from "@/libs/useWidgetContext";

const MESSAGE_CREATED = "MESSAGE_CREATED";
const LOADER_DELAY = 1000;

const useSyncedMessages = ({
  visitorId,
  chatId,
  initialMessages,
  handleMessage,
}: {
  chatId: string;
  visitorId: string | undefined;
  initialMessages: WidgetMessage[];
  handleMessage?: (message: WidgetMessage) => void;
}) => {
  const [messages, setMessages] = useState<WidgetMessage[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const showLoaderTimeoutId = useRef<number | null>(null);
  const { apiKey } = useWidgetContext();
  const sendMessageMutation = useWidgetSendMessageMutation(visitorId, apiKey);

  const _pusherChannel = useMemo(() => {
    if (chatId) {
      return pusherClient.subscribe(chatId);
    }
    return null;
  }, [chatId]);

  const sendMessageIternal = useCallback(
    (content: string) => {
      if (content.trim().length > 0) {
        sendMessageMutation.mutate({ content });
        /*
         window.setTimeout returns number while just setTimeout returns NodeJs.Timeout
         which is not compatible with clearInterval which expects number
         */
        showLoaderTimeoutId.current = window.setTimeout(() => {
          setShowLoader(true);
        }, LOADER_DELAY);
      }
    },
    [sendMessageMutation]
  );

  const messagesSortedByTime = useMemo(() => {
    return messages.sort((a, b) => {
      const firstSentDate = new Date(a.sent_at);
      const secondSentDate = new Date(b.sent_at);

      if (firstSentDate.getTime() > secondSentDate.getTime()) return 1;
      return -1;
    });
  }, [messages]);

  useEffect(() => {
    if (initialMessages.length && !messages.length) {
      setMessages(initialMessages);
    }
  }, [initialMessages]);

  useEffect(() => {
    if (_pusherChannel) {
      const onMessageRecieve = (
        message: WidgetMessageResponse | WidgetMessage
      ) => {
        const standardizedMessage = (
          (message as WidgetMessageResponse)?.ai_message
            ? (message as WidgetMessageResponse).ai_message
            : message
        ) as WidgetMessage;
        setMessages((prevState) => [...prevState, standardizedMessage]);

        if (standardizedMessage.author.role !== AUTHOR_ROLE.VISITOR) {
          setShowLoader(false);
          if (showLoaderTimeoutId) {
            clearTimeout(showLoaderTimeoutId.current as number);
          }
        }
        if (handleMessage) {
          handleMessage(standardizedMessage);
        }
      };

      _pusherChannel.bind(MESSAGE_CREATED, onMessageRecieve);
    }
  }, [_pusherChannel]);

  return {
    messages: messagesSortedByTime,
    showLoader,
    sendMessageIternal,
  };
};

export default useSyncedMessages;
