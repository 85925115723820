import { API_BASE_URL } from "@/env";

export const fetchJson = async (
  url: string,
  token: string,
  options = {}
) => {
  const mergedOptions = {
    ...options,
    headers: {
      "Content-Type": "application/json",
      'Api-Key': `${token}`,
    },
  };

  const response = await fetch(`${API_BASE_URL}${url}`, mergedOptions);

  if (response.status === 200 || response.status === 201) {
    try {
      return response.json();
    } catch {
      return;
    }
  } else if (response.status === 204) {
    return;
  } else {
    throw "API error";
  }
};
