import { FC, ReactNode, useMemo } from "react";
import WidgetMessage from "./WidgetMessage";
import { WidgetMessage as WidgetMessageType } from "@/api/types";
import { getIsIAmSender } from "./utils";
import MessageTypingAnimation from "./MessageTypingAnimation";
import useFonts from "./useFonts";

const WidgetMessagesContainer: FC<{
  messages: WidgetMessageType[];
  visitorId: string;
  firstMessage?: string | ReactNode;
  messageStyles?: { iAmSender?: string; user?: string };
  photo: string;
  showLoader: boolean;
  font: string;
}> = ({ firstMessage, photo, messages, showLoader, font }) => {
  const { font: activeFont } = useFonts(font)

  const messageItems = useMemo(() => {
    return messages.map((item) => (
      <WidgetMessage
        key={item.id}
        font={activeFont}
        icon={
          !getIsIAmSender(item) && (
            <img
              src={photo}
              className="rounded-full w-10 h-10 min-w-10 min-h-10 -mt-6"
            />
          )
        }
        sentAt={item.sent_at}
        isUser={getIsIAmSender(item)}
      >
        {item.content}
      </WidgetMessage>
    ));
  }, [photo, messages, activeFont]);

  return (
    <div className="h-full px-8 py-6 flex gap-12 overflow-scroll flex-col-reverse bg-white">
      <div className="flex flex-col gap-3">
        {firstMessage && (
          <WidgetMessage
            icon={<img src={photo} className="rounded-full w-10 h-10" />}
            isUser={false}
            font={activeFont}
          >
            {firstMessage}
          </WidgetMessage>
        )}
        {messageItems}
        {showLoader && <MessageTypingAnimation />}
      </div>
    </div>
  );
};

export default WidgetMessagesContainer;
