import { hydrateRoot } from "react-dom/client";
import WidgetWrapper from "./components/WidgetWrapper";

function initializeWidget() {
  if (document.readyState !== "loading") {
    onReady();
  } else {
    document.addEventListener("DOMContentLoaded", onReady);
  }
}

function onReady() {
  try {
    const element = document.createElement("div");
    const shadow = element.attachShadow({ mode: "open" });
    const shadowRoot = document.createElement("div");

    const apiKey = document.currentScript?.getAttribute("data-api-key");
    console.log(
      apiKey,
      document.currentScript,
      document.currentScript?.attributes
    );
    shadowRoot.id = "widget-root";

    const component = <WidgetWrapper apiKey={apiKey || undefined} />;

    injectStyle(shadow);
    shadow.appendChild(shadowRoot);
    hydrateRoot(shadowRoot, component);

    document.body.appendChild(element);
  } catch (error) {
    console.warn("Widget initialization failed:", error);
  }
}

function injectStyle(shadowRoot: ShadowRoot) {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = process.env.WIDGET_CSS_URL || "style.css";
  link.type = "text/css";
  shadowRoot.appendChild(link);

  // inject custom styles from index.css
  const styleTag = document.createElement("style");

  // defaul fallback fonts
  styleTag.innerHTML = `
      /* Sora font */
      @font-face {
        font-family: Sora;
        font-weight: 300;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Sora-Light.ttf");
      }
      @font-face {
        font-family: Sora;
        font-weight: 400;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Sora-Regular.ttf");
      }
      @font-face {
        font-family: Sora;
        font-weight: 500;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Sora-Medium.ttf");
      }
      @font-face {
        font-family: Sora;
        font-weight: 600;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Sora-SemiBold.ttf");
      }
      @font-face {
        font-family: Sora;
        font-weight: 700;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Sora-Bold.ttf");
      }
  
      /* Outfit font */
      @font-face {
        font-family: Outfit;
        font-weight: 300;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Outfit-Light.ttf");
      }
      @font-face {
        font-family: Outfit;
        font-weight: 400;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Outfit-Regular.ttf");
      }
      @font-face {
        font-family: Outfit;
        font-weight: 500;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Outfit-Medium.ttf");
      }
      @font-face {
        font-family: Outfit;
        font-weight: 600;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Outfit-SemiBold.ttf");
      }
      @font-face {
        font-family: Outfit;
        font-weight: 700;
        src: url("https://dev-julia-dashboard.globalstudy.technology/widget/src/assets/fonts/Outfit-Bold.ttf");
      }
    `;
  document.head.appendChild(styleTag);
}

initializeWidget();
