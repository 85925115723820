import { WidgetContext } from "@/libs/widgetContext";
import { FC, useState } from "react";
import WidgetContainer from "./WidgetContainer";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/api/reactQueryClient";

const WidgetWrapper: FC<{ apiKey: string | undefined | null }> = ({
  apiKey,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <QueryClientProvider client={queryClient}>
      <WidgetContext.Provider value={{ isOpen, setIsOpen, apiKey: apiKey || 'wnQoXeXBaF4_s6Spob5Pcwq59mU0Ga5ZQwgvS1MocWY' }}>
        <WidgetContainer />
      </WidgetContext.Provider>
    </QueryClientProvider>
  );
};

export default WidgetWrapper;
