/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentClass, FC, useMemo } from "react";
import { IconProps } from "./types";

const Icon: FC<IconProps> = ({ icon, className, size }) => {
  const Icon = useMemo(() => {
    const IconComponent = icon as unknown as ComponentClass<
      Record<string, any>,
      any
    >;
    return <IconComponent className={className} width={size} height={size} />;
  }, [icon, className, size]);

  return <>{Icon}</>;
};

export default Icon;
