import { FC, useCallback } from "react";
import { WidgetHeaderProps } from "./types";
import { motion } from "framer-motion";
import ChevronDown from "../assets/ChevronDown.svg";
import Icon from "./Icon";
import useWidgetContext from "@/libs/useWidgetContext";

const WidgetHeader: FC<WidgetHeaderProps> = ({ name, color, photo }) => {
  const { isOpen, setIsOpen } = useWidgetContext();

  const handleClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <div
      className="h-[88px] px-8 py-6 flex justify-between border-b border-light-grey"
      style={{ background: isOpen ? color : "#fff" }}
    >
      <div className="flex gap-4 items-center">
        <motion.div
          variants={{
            closed: {
              translateX: "-50%",
              translateY: "-25%",
              transition: {
                delay: 0.1
              }
            },
            open: {
              position: "relative",
              translateX: 0,
              translateY: 0,
              transition: {
                delay: 0.2
              }
            },
          }}
        >
          <img src={photo} className="rounded-full min-w-10 w-10 h-10" />
        </motion.div>
        <motion.div
          variants={{
            open: {
              opacity: 1,
              transition: {
                delay: 0.2,
              },
            },
            closed: {
              opacity: 0,
              transition: {
                delay: 0.3,
                duration: 0,
              },
            },
          }}
          className="flex flex-col gap-1"
        >
          <p className="text-sora-titleBold text-white">{name}</p>
          <div className="flex items-center gap-2">
            {/* <Icon name="Online" size={10} /> */}
            <p className="text-sora-titleSlim text-white">online</p>
          </div>
        </motion.div>
      </div>
      <div onClick={handleClick} className="cursor-pointer">
        <Icon
          icon={ChevronDown}
          className="[&>path]:fill-white [&>path]:stroke-white w-9 h-9"
          size={36}
        />
      </div>
    </div>
  );
};

export default WidgetHeader;
