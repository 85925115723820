import { useQuery } from "@tanstack/react-query";
import { WebfontList } from "./types";
import { API_GOOGLE_FONTS_KEY } from "@/env";
import { GOOGLE_FONTS_API_URL } from "@/constants/values";

export const useFontsQuery = (fontFamily: string) => {
  return useQuery({
    queryKey: ["fontsAPI"],
    queryFn: async (): Promise<WebfontList> => {
      const response = await fetch(
        `${GOOGLE_FONTS_API_URL}?key=${API_GOOGLE_FONTS_KEY}&family=${encodeURIComponent(
          fontFamily
        )}`
      );
      return response.json();
    },
    enabled: !!fontFamily,
  });
};
