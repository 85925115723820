import { useFontsQuery } from "@/api/fonts";
import { REQUIRED_FONT_TYPES } from "@/constants/values";
import { useCallback, useEffect, useState } from "react";

const useFonts = (font: string) => {
  const [activeFont, setActiveFont] = useState<string>(font);
  const { data: rawFontFamily } = useFontsQuery(activeFont);

  const _getUrlByFontsFamily = useCallback((fontFamily: string) => {
    return rawFontFamily?.items.find((font) => font.family === fontFamily)?.files;
  }, [rawFontFamily]);

  const uploadFont = useCallback(async (font: string) => {
    const fontFiles = _getUrlByFontsFamily(font)
    for(const fontKey of Object.keys(fontFiles || {})) {
      if(REQUIRED_FONT_TYPES.includes(fontKey)) {
        const fontFace = new FontFace(font, `url(${(fontFiles || {})[fontKey]})`, {
          weight: fontKey,
        });
        const loadedFont = await fontFace.load();

        document.fonts.add(loadedFont);
      }
    }
  }, [_getUrlByFontsFamily]);

  useEffect(() => {
    if (activeFont && rawFontFamily) {
      uploadFont(activeFont);
    }
  }, [activeFont, rawFontFamily, uploadFont]);

  useEffect(() => {
    if (typeof font === "string") {
      setActiveFont(font);
    }
  }, [font]);

  return { font: activeFont, setFont: setActiveFont };
};

export default useFonts;
