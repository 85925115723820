/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useRef, useState } from "react";
import Send from "../assets/Send.svg";
import Icon from "./Icon";

const WidgetInput: FC<{ sendMessage: (content: string) => void }> = ({
  sendMessage,
}) => {
  const [value, setValue] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleSendMessage = useCallback(() => {
    sendMessage(value);
    setValue("");
  }, [value, sendMessage]);

  useEffect(() => {
    if (!textareaRef.current) return;
    const ENTER_KEY = "Enter";
    const currentTextAreaElement = textareaRef.current;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === ENTER_KEY) {
        if (event.shiftKey) {
          setValue((prevValue) => prevValue + "\n");
        } else {
          event.preventDefault();
          handleSendMessage();
        }
      }
    };

    currentTextAreaElement.addEventListener("keydown", handleKeyDown);

    return () => {
      if (currentTextAreaElement) {
        currentTextAreaElement.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [handleSendMessage]);

  return (
    <div className="border-t border-light-grey px-8 py-6 bg-white">
      <div className="flex gap-4 w-full">
        <textarea
          className="h-12 p-4 w-full rounded-10 border border-lighter-grey leading-[1] resize-none"
          placeholder="Type a message..."
          value={value}
          onChange={(e) => setValue(e.target.value)}
          ref={textareaRef}
        />
        <div
          className="w-12 min-h-12 rounded-10 flex justify-center items-center border border-lighter-grey cursor-pointer"
          onClick={handleSendMessage}
        >
          <Icon
            icon={Send}
            className="[&>path]:fill-[#252B42] [&>path]:stroke-[#252B42] pt-[4px]"
            size={25}
          />
        </div>
      </div>
    </div>
  );
};

export default WidgetInput;
